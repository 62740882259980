<template>
  <div class="container">
    <div class="card border-0 shadow-sm">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col my-auto">
            <h3 class="fw-bold text-primary my-auto">Connected domains</h3>
          </div>
          <div class="col-auto my-auto ms-auto">
            <router-link to="/domains/create" class="btn btn-primary w-100">
              <i class="far fa-plus me-2"></i>Add new domain
            </router-link>
          </div>
        </div>
        <div>
          <DataList
            :api-resource="baseUrl + '/domains'"
            display-name="Domain"
            @navigateTo="viewDomain"
            :fields="dataListFields"
            default-sort="created_at"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataList from "@/views/components/tables/DataList";

export default {
  components: {
    DataList,
  },
  data() {
    return {
      domains: [],
      dataListFields: [
        {
          field_name: "domain",
          display_name: "Domain",
          type: "text",
          hide_sm: true,
          highlight: true,
        },
        {
          field_name: "id",
          display_name: "ID",
          type: "text",
          sortable: false,
          highlight: false,
        },
        {
          field_name: "status",
          display_name: "Status",
          hide_header: true,
          type: "badge",
          badge_data: {
            pending: "btn-dark",
            verified: "btn-success",
            swandoola_verified: "btn-success",
            sendgrid_verified: "btn-success",
            sender_added: "btn-success",
            sendgrid_subuser_added: "btn-success",
            domain_assigned_to_subuser: "btn-success",
          },
          sortable: false,
          capitalize: true,
          class_list: "text-end",
        },
      ],
    };
  },
  computed: {
    baseUrl() {
      return process.env.VUE_APP_API_URL;
    },
  },
  methods: {
    viewDomain(c) {
      this.$router.push("/domains/" + c.id);
    },
    fetchDomains() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/domains")
        .then(({ data }) => {
          this.domains = data;
        });
    },
  },
  created() {
    this.fetchDomains();
  },
};
</script>

<style>
</style>